* {
  margin: 0;
  padding: 0;
  max-width: 100vw !important;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
}

html,
body {
  overflow-x: hidden;
}
div.bm-menu,
div.bg-white {
  height: fit-content;
}
video {
  object-fit: cover !important;
  height: 100% !important;
}